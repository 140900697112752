import * as React from 'react';
import styled from 'styled-components';

import {
  Text,
  Box,
  Image,
  InlineBox,
  PriceSection,
  Seo,
} from '../../../components';
import MainLayout from '../../../layouts/MainLayout';
import ArrowIcon from '../../../assets/images/arrow.inline.svg';
import HeroContentSAPPReorder from '../../../components/HeroContentSAPPReorder';

const HTUItem = styled(Text)`
  padding-bottom: 20px;

  &::last-child {
    padding-bottom: 0;
  }

  span.htu-item-text {
    position: relative;
    padding-left: 8px;
    top: -2px;
  }
`;

const QuoraPixel = ({ data, location }) => {
  return (
    <MainLayout heroContent={<HeroContentSAPPReorder />}>
      <Seo title="SAPP Subscriptions" />
      <Box px={[4, 100]} py={50} textAlign="center">
        <Text variant="h2">About SAPP Reorder</Text>
        <Text px={[0, 160]} pt={3}>
          SAPP Reorder allows Shopify merchants to add a Reorder button next to
          each order in their customer's accounts. This button enables customers
          to reorder with customization: add or remove items and modify the
          quantity. Our app streamlines the reordering process, making it
          convenient for customers, and helps merchants earn customer loyalty.
          Merchants can also customize the design and text of the reorder button
          to match their store's branding and offer discounts on reorders to
          increase sales.
        </Text>
      </Box>
      <Box bg="accent2" px={[0, 100]} pt={50}>
        <Box display="flex" flexWrap="wrap" mx={[0, -3]}>
          <Box
            width={[1, 1 / 2]}
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="flex-end"
            p={[4, 0]}
          >
            <Image
              src="/assets/images/how-to-use.png"
              alt="how-to-use"
              width={['100%', 380]}
            />
          </Box>
          <Box width={[1, 1 / 2]} p={[4, 3]}>
            <Text variant="h2" mb={3}>
              How to Use
            </Text>
            <HTUItem variant="h5" fontWeight="500">
              <ArrowIcon></ArrowIcon>{' '}
              <span className="htu-item-text">Install the app</span>
            </HTUItem>
            <HTUItem variant="h5" fontWeight="500">
              <ArrowIcon></ArrowIcon>{' '}
              <span className="htu-item-text">
                Setup discount setting (optional)
              </span>
            </HTUItem>
            <HTUItem variant="h5" fontWeight="500">
              <ArrowIcon></ArrowIcon>{' '}
              <span className="htu-item-text">
                Setup button setting (optional)
              </span>
            </HTUItem>
          </Box>
        </Box>
      </Box>
      <Box bg="light1" px={[0, 100]} py={50}>
        <Box display="flex" flexWrap="wrap" mx={[0, -3]}>
          <Box width={[1, 1 / 2]} p={[4, 3]}>
            <Box textAlign="right" display="block" pr={4}>
              <InlineBox display="inline-block" textAlign="left">
                <Text variant="h2" mb={3}>
                  Features
                </Text>
                <HTUItem variant="h5" fontWeight="500">
                  <ArrowIcon></ArrowIcon>{' '}
                  <span className="htu-item-text">Easy Reordering</span>
                </HTUItem>
                <HTUItem variant="h5" fontWeight="500">
                  <ArrowIcon></ArrowIcon>{' '}
                  <span className="htu-item-text">Editable Design</span>
                </HTUItem>
                <HTUItem variant="h5" fontWeight="500">
                  <ArrowIcon></ArrowIcon>{' '}
                  <span className="htu-item-text">Edit Orders</span>
                </HTUItem>
                <HTUItem variant="h5" fontWeight="500">
                  <ArrowIcon></ArrowIcon>{' '}
                  <span className="htu-item-text">Quantity Control</span>
                </HTUItem>
                <HTUItem variant="h5" fontWeight="500">
                  <ArrowIcon></ArrowIcon>{' '}
                  <span className="htu-item-text">Discount Offers</span>
                </HTUItem>
              </InlineBox>
            </Box>
          </Box>
          <Box
            width={[1, 1 / 2]}
            display="flex"
            justifyContent="center"
            flexDirection="column"
            p={[4, 0]}
          >
            <Image
              src="/assets/images/features.png"
              alt="how-to-use"
              width={['100%', 380]}
            />
          </Box>
        </Box>
      </Box>
      <Box bg="primary">
        <PriceSection
          price={2}
          appUrl="https://apps.shopify.com/sapp-reorder"
        />
      </Box>
    </MainLayout>
  );
};

export default QuoraPixel;

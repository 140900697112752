import React from 'react';
import styled from 'styled-components';
// import ReactPlayer from 'react-player';

import Box from './Box';
import Text from './Text';
import Link from './Link';
import Image from './Image';

import SAPPreorderPreview from '../assets/images/sapp-reorder.png';
const HeroContainer = styled.div`
  width: 100%;
  min-height: 560px;
  height: 100%;
  padding: 0 100px;
  display: flex;
  flex-wrap: wrap;

  @media only screen and (max-width: 768px) {
    min-height: 100%;
    background: none;
    padding: 32px;
  }

  .hero-img {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
  }
`;

const HeroContentSAPPReorder = () => (
  <HeroContainer>
    <Box
      width={[1, 1, 1, 2 / 4]}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="flex-start"
      height="100%"
    >
      <Text variant="h1" color="light2">
        Streamline Repeat Orders
      </Text>
      <Text variant="h1" color="light2">
        Offer Discount
      </Text>
      <Text variant="h1" color="light2">
        Increase Customer Loyalty
      </Text>
      <Link to="https://apps.shopify.com/sapp-reorder" variant="button" mt={4}>
        Install Now
      </Link>
    </Box>
    <Box
      width={[1, 1, 1, 2 / 4]}
      display="flex"
      justifyContent="center"
      alignItems="center"
      p={[0, 0, 0, 5]}
      mt={[4, 4, 4, 0]}
    >
      <Image
        src={SAPPreorderPreview}
        width="80%"
        style={{ boxShadow: '0 0 16px 4px rgba(0,0,0,.1)' }}
      />
    </Box>
  </HeroContainer>
);

export default HeroContentSAPPReorder;
